import { PushNotoficationService } from './services/push-notofication.service';
import { RouterModule } from '@angular/router';
import { InitService } from './services/init.service';
import { FacebookService } from './services/facebook.service';
import { MapModule } from './modules/map/map.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { GenericHttpService } from './services/generic-http.service';
import { CookiesService } from './services/cookies.service';
import { AuthService } from './services/auth.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ButtonComponent } from './components/button/button.component';
import { InputComponent } from './components/input/input.component';
import { FormMessageComponent } from './components/form-message/form-message.component';
import { HeaderComponent } from './components/header/header.component';
import { SelectComponent } from './components/select/select.component';
import { GuardiansService } from './services/guardians.service';
import { JwtInterceptorService } from '@services/jwt-interceptor.service';
import { AuthGuard } from '@shared/guards/auth.guard';
import { ImageUploadComponent } from './components/image-upload/image-upload.component';
import { AlertVideoStreamComponent } from './components/alert-video-stream/alert-video-stream.component';
import { HistoryService } from '@services/history.service';
import { LocationService } from '@services/location.service';
import { SpinerComponent } from './components/spiner/spiner.component';
import { HistoryItemComponent } from './components/history-item/history-item.component';
import { AlarmInfoListComponent } from './components/alarm-info-list/alarm-info-list.component';
import { GoogleAddressService } from '@services/google-address.service';
import { VoucherService } from '@services/voucher.service';

const components = [
  ButtonComponent,
  InputComponent,
  FormMessageComponent,
  HeaderComponent,
  SelectComponent,
  ImageUploadComponent,
  AlertVideoStreamComponent,
  SpinerComponent,
  HistoryItemComponent,
  AlarmInfoListComponent
];

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    MapModule.forRoot({
      apiKey: 'AIzaSyBZvIgfwqH-YffbqhmqPUN1NbUC1RWlbsE'
    })
  ],
  declarations: [
    ...components,
  ],
  exports: [
    ...components,
    MapModule,
  ],
  providers: [
    AuthService,
    CookiesService,
    GenericHttpService,
    FacebookService,
    InitService,
    GuardiansService,
    HistoryService,
    LocationService,
    AuthGuard,
    GoogleAddressService,
    VoucherService,
    PushNotoficationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptorService,
      multi: true
    },
  ]
})
export class SharedModule { }
