import { Observable, ReplaySubject } from 'rxjs';
import { GenericHttpService } from './generic-http.service';
import { Injectable } from '@angular/core';
import { apiUrls } from '../../config';
import { CountriesAndCodes } from '@shared/models/countries-codes';
import { SelectOption } from '@shared/components/select/select-options';

@Injectable({
  providedIn: 'root'
})
export class InitService {
  private countriesAndCodesSubject = new ReplaySubject<CountriesAndCodes>(1);
  public countriesAndCodesSub$: Observable<CountriesAndCodes> = this.countriesAndCodesSubject.asObservable();
  public codesOptions: SelectOption[] = [];

  constructor(
    private httpService: GenericHttpService,
  ) {
    this.getCountriesAndCodes();
  }

  getCountriesAndCodes() {
    this.httpService.get(apiUrls.dictionaries.countriesAndCodes)
      .subscribe((codes: CountriesAndCodes) => this.countriesAndCodesSubject.next(codes));
  }

  formCodesOptions() {
    this.countriesAndCodesSub$.subscribe((codes: CountriesAndCodes) => {
      codes.countriesAndCountryCodes.forEach(code => {
        const option: SelectOption = {
          value: code.phoneCountryCode,
          label: `${code.countryName} ${code.phoneCountryCode}`
        };

        this.codesOptions.push(option);
      });
    });

    return this.codesOptions;
  }

  public isMobile(): boolean {
    return [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i]
      .some(pattern => !!navigator.userAgent.match(pattern));
  }

  public isAndroid(): boolean {
    return [/Android/i]
      .some(pattern => !!navigator.userAgent.match(pattern));
  }

  public isIOs(): boolean {
    return [/webOS/i, /iPhone/i, /iPad/i, /iPod/i]
      .some(pattern => !!navigator.userAgent.match(pattern));
  }
}
