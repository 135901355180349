import { ControlValueAccessor } from '@angular/forms';
var InputComponent = /** @class */ (function () {
    function InputComponent() {
        this.inputValue = '';
        this.onChangeCallback = function (_) { };
        this.onTouchedCallback = function () { };
    }
    Object.defineProperty(InputComponent.prototype, "value", {
        get: function () {
            return this.inputValue;
        },
        set: function (value) {
            if (value !== this.inputValue) {
                this.inputValue = value;
                this.onChangeCallback(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    InputComponent.prototype.writeValue = function (value) {
        this.inputValue = value;
    };
    InputComponent.prototype.registerOnChange = function (fn) { this.onChangeCallback = fn; };
    InputComponent.prototype.registerOnTouched = function (fn) { this.onTouchedCallback = fn; };
    InputComponent.prototype.onBlur = function () {
        this.onTouchedCallback();
    };
    return InputComponent;
}());
export { InputComponent };
