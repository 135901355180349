import { AuthService } from '@services/auth.service';
import { GenericHttpService } from '@services/generic-http.service';
import { apiUrls } from '../../config';
import * as i0 from "@angular/core";
import * as i1 from "./generic-http.service";
import * as i2 from "./auth.service";
var GuardiansService = /** @class */ (function () {
    function GuardiansService(httpService, auth) {
        this.httpService = httpService;
        this.auth = auth;
    }
    GuardiansService.prototype.getAllGuardians = function () {
        var url = "/" + this.auth.userPhone + apiUrls.guardians.base;
        return this.httpService.get(url);
    };
    GuardiansService.prototype.getGuardiansPositions = function () {
        var url = "" + apiUrls.position.user + this.auth.userPhone + apiUrls.position.guardians;
        return this.httpService.get(url);
    };
    GuardiansService.prototype.addGuardian = function (guradian) {
        var url = "/" + this.auth.userPhone + apiUrls.guardians.base + apiUrls.guardians.add;
        var body = {
            guardians: [guradian]
        };
        return this.httpService.post(url, body);
    };
    GuardiansService.prototype.declineGuardian = function (guradian) {
        var url = "/" + this.auth.userPhone + apiUrls.guardians.base + apiUrls.guardians.add;
        var body = {
            guardians: [guradian]
        };
        return this.httpService.post(url, body);
    };
    GuardiansService.prototype.getImageByPhone = function (phoneNumber) {
        var url = "/" + phoneNumber + "/images";
        return this.httpService.get(url);
    };
    GuardiansService.ngInjectableDef = i0.defineInjectable({ factory: function GuardiansService_Factory() { return new GuardiansService(i0.inject(i1.GenericHttpService), i0.inject(i2.AuthService)); }, token: GuardiansService, providedIn: "root" });
    return GuardiansService;
}());
export { GuardiansService };
