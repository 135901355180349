import { AuthService } from '@services/auth.service';
import { Injectable } from '@angular/core';
import { GenericHttpService } from '@services/generic-http.service';
import { Observable } from 'rxjs';
import { apiUrls } from '../../config';

@Injectable({
  providedIn: 'root'
})
export class GuardiansService {

  constructor(
    private httpService: GenericHttpService,
    private auth: AuthService
  ) { }

  getAllGuardians(): Observable<any> {
    const url = `/${this.auth.userPhone}${apiUrls.guardians.base}`;
    return this.httpService.get(url);
  }

  getGuardiansPositions(): Observable<any> {
    const url = `${apiUrls.position.user}${this.auth.userPhone}${apiUrls.position.guardians}`;
    return this.httpService.get(url);
  }

  addGuardian(guradian): Observable<any> {
    const url = `/${this.auth.userPhone}${apiUrls.guardians.base}${apiUrls.guardians.add}`;
    const body = {
      guardians: [guradian]
    };
    return this.httpService.post(url, body);
  }

  declineGuardian(guradian): Observable<any> {
    const url = `/${this.auth.userPhone}${apiUrls.guardians.base}${apiUrls.guardians.add}`;
    const body = {
      guardians: [guradian]
    };
    return this.httpService.post(url, body);
  }

  getImageByPhone(phoneNumber: string) {
    const url = `/${phoneNumber}/images`;
    return this.httpService.get(url);
  }
}
