import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { mergeMapTo } from 'rxjs/operators';
import { take } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { GenericHttpService } from './generic-http.service';
import { apiUrls } from '../../config';

@Injectable({
  providedIn: 'root'
})
export class PushNotoficationService {

  currentMessage = new BehaviorSubject(null);

  constructor(
    private angularFireDB: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    private angularFireMessaging: AngularFireMessaging,
    private httpService: GenericHttpService,
    private auth: AuthService
  ) {
    this.angularFireMessaging.messaging.subscribe(
      (messaging) => {
        messaging.onMessage = messaging.onMessage.bind(messaging);
        messaging.onTokenRefresh = messaging.onTokenRefresh.bind(messaging);
      }
    );
  }

  // request permission for notification from firebase cloud messaging
  requestPermission(isLogout?: boolean) {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        if (isLogout) {
          this.unregisterPush(token).subscribe(res => {
            console.log('unregister push', res);
          });
        } else {
          this.registerPush(token).subscribe(res => {
            console.log('register push', res);
          });
        }

      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  // hook method when new notification received in foreground
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        console.log('new message received. ', payload);
        this.currentMessage.next(payload);
      });
  }

  registerPush(userToken: string): Observable<any> {
    const url = `${apiUrls.push.base}${apiUrls.push.register}`;
    const body = {
      phoneNumber: this.auth.userPhone,
      platform: 'WEB',
      regId: userToken
    };

    return this.httpService.post(url, body);
  }

  unregisterPush(userToken: string): Observable<any> {
    const url = `${apiUrls.push.base}${apiUrls.push.unregister}`;
    const body = {
      phoneNumber: this.auth.userPhone,
      regId: userToken
    };

    return this.httpService.post(url, body);
  }

  deleteRegistereredPush(userToken: string): Observable<any> {
    const url = `${apiUrls.push.base}${apiUrls.push.register}`;
    const body = {
      phoneNumber: this.auth.userPhone,
      regId: userToken
    };

    return this.httpService.post(url, body);
  }
}
