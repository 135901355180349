import { ReplaySubject } from 'rxjs';
import { GenericHttpService } from './generic-http.service';
import { apiUrls } from '../../config';
import * as i0 from "@angular/core";
import * as i1 from "./generic-http.service";
var InitService = /** @class */ (function () {
    function InitService(httpService) {
        this.httpService = httpService;
        this.countriesAndCodesSubject = new ReplaySubject(1);
        this.countriesAndCodesSub$ = this.countriesAndCodesSubject.asObservable();
        this.codesOptions = [];
        this.getCountriesAndCodes();
    }
    InitService.prototype.getCountriesAndCodes = function () {
        var _this = this;
        this.httpService.get(apiUrls.dictionaries.countriesAndCodes)
            .subscribe(function (codes) { return _this.countriesAndCodesSubject.next(codes); });
    };
    InitService.prototype.formCodesOptions = function () {
        var _this = this;
        this.countriesAndCodesSub$.subscribe(function (codes) {
            codes.countriesAndCountryCodes.forEach(function (code) {
                var option = {
                    value: code.phoneCountryCode,
                    label: code.countryName + " " + code.phoneCountryCode
                };
                _this.codesOptions.push(option);
            });
        });
        return this.codesOptions;
    };
    InitService.prototype.isMobile = function () {
        return [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i]
            .some(function (pattern) { return !!navigator.userAgent.match(pattern); });
    };
    InitService.prototype.isAndroid = function () {
        return [/Android/i]
            .some(function (pattern) { return !!navigator.userAgent.match(pattern); });
    };
    InitService.prototype.isIOs = function () {
        return [/webOS/i, /iPhone/i, /iPad/i, /iPod/i]
            .some(function (pattern) { return !!navigator.userAgent.match(pattern); });
    };
    InitService.ngInjectableDef = i0.defineInjectable({ factory: function InitService_Factory() { return new InitService(i0.inject(i1.GenericHttpService)); }, token: InitService, providedIn: "root" });
    return InitService;
}());
export { InitService };
