import * as i0 from "@angular/core";
var JwtInterceptorService = /** @class */ (function () {
    function JwtInterceptorService() {
    }
    JwtInterceptorService.prototype.intercept = function (request, next) {
        request = request.clone({
            withCredentials: true
        });
        return next.handle(request);
    };
    JwtInterceptorService.ngInjectableDef = i0.defineInjectable({ factory: function JwtInterceptorService_Factory() { return new JwtInterceptorService(); }, token: JwtInterceptorService, providedIn: "root" });
    return JwtInterceptorService;
}());
export { JwtInterceptorService };
