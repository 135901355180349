import { Component, Input, forwardRef, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'besafe-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true
    }
  ]
})
export class SelectComponent implements ControlValueAccessor {

  @Input() rootClass: string;
  @Input() options: any = [];
  @Input() name: string;
  @Input() placeholder: string;

  @Output() public selected = new EventEmitter();

  selectedItem: any;

  propagateChange: any = () => {};

  writeValue(value) {
    if (value) {
      this.selectedItem = value;
    }
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  valueChange(event: any): void {
    this.selectedItem = event;
    this.propagateChange(this.selectedItem);
  }

}
