import { AuthService } from './auth.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';
import { GenericHttpService } from './generic-http.service';
import { apiUrls } from '../../config';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/database";
import * as i2 from "@angular/fire/auth";
import * as i3 from "@angular/fire/messaging";
import * as i4 from "./generic-http.service";
import * as i5 from "./auth.service";
var PushNotoficationService = /** @class */ (function () {
    function PushNotoficationService(angularFireDB, angularFireAuth, angularFireMessaging, httpService, auth) {
        this.angularFireDB = angularFireDB;
        this.angularFireAuth = angularFireAuth;
        this.angularFireMessaging = angularFireMessaging;
        this.httpService = httpService;
        this.auth = auth;
        this.currentMessage = new BehaviorSubject(null);
        this.angularFireMessaging.messaging.subscribe(function (messaging) {
            messaging.onMessage = messaging.onMessage.bind(messaging);
            messaging.onTokenRefresh = messaging.onTokenRefresh.bind(messaging);
        });
    }
    // request permission for notification from firebase cloud messaging
    PushNotoficationService.prototype.requestPermission = function (isLogout) {
        var _this = this;
        this.angularFireMessaging.requestToken.subscribe(function (token) {
            if (isLogout) {
                _this.unregisterPush(token).subscribe(function (res) {
                    console.log('unregister push', res);
                });
            }
            else {
                _this.registerPush(token).subscribe(function (res) {
                    console.log('register push', res);
                });
            }
        }, function (err) {
            console.error('Unable to get permission to notify.', err);
        });
    };
    // hook method when new notification received in foreground
    PushNotoficationService.prototype.receiveMessage = function () {
        var _this = this;
        this.angularFireMessaging.messages.subscribe(function (payload) {
            console.log('new message received. ', payload);
            _this.currentMessage.next(payload);
        });
    };
    PushNotoficationService.prototype.registerPush = function (userToken) {
        var url = "" + apiUrls.push.base + apiUrls.push.register;
        var body = {
            phoneNumber: this.auth.userPhone,
            platform: 'WEB',
            regId: userToken
        };
        return this.httpService.post(url, body);
    };
    PushNotoficationService.prototype.unregisterPush = function (userToken) {
        var url = "" + apiUrls.push.base + apiUrls.push.unregister;
        var body = {
            phoneNumber: this.auth.userPhone,
            regId: userToken
        };
        return this.httpService.post(url, body);
    };
    PushNotoficationService.prototype.deleteRegistereredPush = function (userToken) {
        var url = "" + apiUrls.push.base + apiUrls.push.register;
        var body = {
            phoneNumber: this.auth.userPhone,
            regId: userToken
        };
        return this.httpService.post(url, body);
    };
    PushNotoficationService.ngInjectableDef = i0.defineInjectable({ factory: function PushNotoficationService_Factory() { return new PushNotoficationService(i0.inject(i1.AngularFireDatabase), i0.inject(i2.AngularFireAuth), i0.inject(i3.AngularFireMessaging), i0.inject(i4.GenericHttpService), i0.inject(i5.AuthService)); }, token: PushNotoficationService, providedIn: "root" });
    return PushNotoficationService;
}());
export { PushNotoficationService };
