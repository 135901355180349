import { Injectable } from '@angular/core';
import { UserPosition } from '@shared/models/user-position';
import { MapsAPILoader } from '@shared/modules/map/services/maps-api-loader.service';

declare var google;

@Injectable({
  providedIn: 'root'
})
export class GoogleAddressService {

  constructor(
    private mapsAPILoader: MapsAPILoader,
  ) { }

  public getAddressFromLocation(location: UserPosition | any) {
    const addressSearch = {
      location: {
        lng: location.longtitude,
        lat: location.latitude
      }
    };
    this.mapsAPILoader.load().then(() => {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode(addressSearch, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            console.log(results);
            return results[0].formatted_address;
          } else {
            return 'No address found';
          }
        } else {
          window.alert('Geocoder failed due to: ' + status);
        }
      });
    });
  }
}
