import { apiUrls } from './../../config/index';
import { AuthService } from './auth.service';
import { GenericHttpService } from './generic-http.service';
import * as i0 from "@angular/core";
import * as i1 from "./generic-http.service";
import * as i2 from "./auth.service";
var HistoryService = /** @class */ (function () {
    function HistoryService(httpService, auth) {
        this.httpService = httpService;
        this.auth = auth;
    }
    HistoryService.prototype.getClientHistory = function (page) {
        var url = apiUrls.history.base + "/" + this.auth.userPhone + "?showReceived=true&p=" + page;
        return this.httpService.get(url);
    };
    HistoryService.prototype.getAlarmTrace = function (hash) {
        var url = "/m/" + hash;
        return this.httpService.get(url);
    };
    HistoryService.prototype.getUserTraceCoordinates = function (hash) {
        var url = "/m/" + hash + "/coordinates";
        return this.httpService.get(url);
    };
    HistoryService.ngInjectableDef = i0.defineInjectable({ factory: function HistoryService_Factory() { return new HistoryService(i0.inject(i1.GenericHttpService), i0.inject(i2.AuthService)); }, token: HistoryService, providedIn: "root" });
    return HistoryService;
}());
export { HistoryService };
