import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CookiesService } from '@services/cookies.service';
import { environment } from '@environments/environment.prod';
import { config } from '../../config';
import { LocalStorageService } from '@services/local-storage.service';
import * as i0 from "@angular/core";
import * as i1 from "./cookies.service";
import * as i2 from "@angular/common/http";
import * as i3 from "./local-storage.service";
import * as i4 from "@angular/router";
var GenericHttpService = /** @class */ (function () {
    function GenericHttpService(cookiesService, http, localStorageService, cookieService, router) {
        this.cookiesService = cookiesService;
        this.http = http;
        this.localStorageService = localStorageService;
        this.cookieService = cookieService;
        this.router = router;
        this.deafultContentType = {
            name: 'Content-Type',
            value: 'application/json;charset=utf-8'
        };
        this.deafultAcceptType = {
            name: 'Accept',
            value: 'application/json, text/plain, */*'
        };
        this.apiUrl = environment.baseUrl;
    }
    GenericHttpService.prototype.get = function (path, searchParams) {
        var _this = this;
        if (searchParams === void 0) { searchParams = {}; }
        var fullPath = "" + this.apiUrl + path;
        var params = new HttpParams();
        Object.keys(searchParams).forEach(function (key) {
            params.set(key, searchParams[key]);
        });
        var httpOptions = {
            headers: this.shapeHeaders(),
            withCredentials: true,
            search: params
        };
        return this.http
            .get(fullPath, httpOptions)
            .pipe(catchError(function (error) { return _this.handleError(error); }));
    };
    GenericHttpService.prototype.post = function (path, body) {
        var _this = this;
        if (body === void 0) { body = {}; }
        var fullPath = "" + this.apiUrl + path;
        var jsonBody = JSON.stringify(body);
        var httpOptions = {
            headers: this.shapeHeaders()
        };
        return this.http.post(fullPath, jsonBody, httpOptions)
            .pipe(catchError(function (error) { return _this.handleError(error); }));
    };
    GenericHttpService.prototype.del = function (path) {
        var _this = this;
        var fullPath = "" + this.apiUrl + path;
        var httpOptions = {
            headers: this.shapeHeaders()
        };
        return this.http.delete(fullPath, httpOptions)
            .pipe(catchError(function (error) { return _this.handleError(error); }));
    };
    GenericHttpService.prototype.put = function (path, body) {
        var _this = this;
        if (body === void 0) { body = {}; }
        var fullPath = "" + this.apiUrl + path;
        var jsonBody = JSON.stringify(body);
        var httpOptions = {
            headers: this.shapeHeaders()
        };
        return this.http
            .put(fullPath, jsonBody, httpOptions)
            .pipe(catchError(function (error) { return _this.handleError(error); }));
    };
    GenericHttpService.prototype.shapeHeaders = function () {
        var accessToken = this.cookiesService
            .getCookie(config.accessTokenKey);
        var headers = new HttpHeaders()
            .set(this.deafultContentType.name, this.deafultContentType.value);
        // .set(this.deafultAcceptType.name, this.deafultAcceptType.value);
        // if (Boolean(accessToken)) {
        //   headers = headers.append('Authorization', `Bearer ${accessToken}`);
        // }
        return headers;
    };
    GenericHttpService.prototype.handleError = function (responseError) {
        console.log(responseError);
        // || !this.cookieService.getCookie('JSESSIONID')
        if (responseError.status === 401) {
            // TODO: Show unauthorized error
            console.log('Not logged in!');
            this.clearSession();
        }
        return throwError(responseError.error);
    };
    GenericHttpService.prototype.clearSession = function () {
        this.localStorageService.clear();
        this.cookiesService.deleteCookie('JSESSIONID');
        this.cookiesService.deleteCookie('SESSION');
        this.router.navigate(['/auth']);
    };
    GenericHttpService.ngInjectableDef = i0.defineInjectable({ factory: function GenericHttpService_Factory() { return new GenericHttpService(i0.inject(i1.CookiesService), i0.inject(i2.HttpClient), i0.inject(i3.LocalStorageService), i0.inject(i1.CookiesService), i0.inject(i4.Router)); }, token: GenericHttpService, providedIn: "root" });
    return GenericHttpService;
}());
export { GenericHttpService };
