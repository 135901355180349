import * as i0 from "@angular/core";
var CookiesService = /** @class */ (function () {
    function CookiesService() {
    }
    CookiesService.prototype.getCookie = function (name) {
        var matches = document.cookie.match(new RegExp('(?:^|; )' +
            name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
            '=([^;]*)'));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    };
    CookiesService.prototype.setCookie = function (name, value, options) {
        if (options === void 0) { options = {}; }
        options = Object.assign({ path: '/' }, options);
        var expires = options.expires;
        if (typeof expires === 'number' && expires) {
            var d = new Date();
            d.setTime(d.getTime() + expires * 1000);
            expires = options.expires = d;
        }
        if (expires && expires.toUTCString) {
            options.expires = expires.toUTCString();
        }
        value = encodeURIComponent(value);
        var updatedCookie = name + '=' + value;
        for (var propName in options) {
            if (options.hasOwnProperty(propName)) {
                updatedCookie += '; ' + propName;
                var propValue = options[propName];
                if (propValue !== true) {
                    updatedCookie += '=' + propValue;
                }
            }
        }
        document.cookie = updatedCookie;
    };
    CookiesService.prototype.deleteCookie = function (name) {
        this.setCookie(name, '', {
            expires: -1
        });
    };
    CookiesService.ngInjectableDef = i0.defineInjectable({ factory: function CookiesService_Factory() { return new CookiesService(); }, token: CookiesService, providedIn: "root" });
    return CookiesService;
}());
export { CookiesService };
