import {
  Component,
  Input,
  forwardRef,
  HostBinding
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR
} from '@angular/forms';

@Component({
  selector: 'besafe-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true
    }
  ]
})
export class InputComponent implements ControlValueAccessor {

  @Input() label: string;
  @Input() name: string;
  @Input() type: string;
  @Input() placeholder: string;

  @HostBinding('class.input-component') true;

  public inputValue: any = '';

  onChangeCallback: any = (_: any) => {};
  onTouchedCallback: any = () => {};

  get value(): any {
    return this.inputValue;
  }

  set value(value: any) {
    if (value !== this.inputValue) {
      this.inputValue = value;
      this.onChangeCallback(value);
    }
  }

  writeValue(value) {
    this.inputValue = value;
  }

  registerOnChange(fn: (_: any) => {}): void { this.onChangeCallback = fn; }

  registerOnTouched(fn: () => {}): void { this.onTouchedCallback = fn; }

  public onBlur() {
    this.onTouchedCallback();
  }

}
