import { Injectable } from '@angular/core';
import { GenericHttpService } from './generic-http.service';

@Injectable({
  providedIn: 'root'
})
export class VoucherService {

  constructor(
    private httpService: GenericHttpService,
  ) { }

  public voucherReserve(voucherData) {
    const url = '/coupon/reserve';

    return this.httpService.post(url, voucherData);
  }
}
