import { MapsAPILoader } from '@shared/modules/map/services/maps-api-loader.service';
import * as i0 from "@angular/core";
import * as i1 from "../modules/map/services/maps-api-loader.service";
var GoogleAddressService = /** @class */ (function () {
    function GoogleAddressService(mapsAPILoader) {
        this.mapsAPILoader = mapsAPILoader;
    }
    GoogleAddressService.prototype.getAddressFromLocation = function (location) {
        var addressSearch = {
            location: {
                lng: location.longtitude,
                lat: location.latitude
            }
        };
        this.mapsAPILoader.load().then(function () {
            var geocoder = new google.maps.Geocoder();
            geocoder.geocode(addressSearch, function (results, status) {
                if (status === google.maps.GeocoderStatus.OK) {
                    if (results[0]) {
                        console.log(results);
                        return results[0].formatted_address;
                    }
                    else {
                        return 'No address found';
                    }
                }
                else {
                    window.alert('Geocoder failed due to: ' + status);
                }
            });
        });
    };
    GoogleAddressService.ngInjectableDef = i0.defineInjectable({ factory: function GoogleAddressService_Factory() { return new GoogleAddressService(i0.inject(i1.MapsAPILoader)); }, token: GoogleAddressService, providedIn: "root" });
    return GoogleAddressService;
}());
export { GoogleAddressService };
