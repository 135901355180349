import { Injectable } from '@angular/core';
import { UserPosition } from '@shared/models/user-position';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  public getLocation(): UserPosition {
    const location: UserPosition = {
      latitude: 0,
      longitude: 0,
      accuracy: 0
    };

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        // location = {...position.coords}
        location.latitude = position.coords.latitude;
        location.longitude = position.coords.longitude;
        location.accuracy = position.coords.accuracy;
      });
    }

    return location;
  }
}
