export const environment = {
  production: true,
  hmr: false,
  baseUrl: 'https://bsafe.bipper.com',
  // branchKey: 'key_live_bmtXIrG7EWYgi041WbMAmbmkDCl3r0Nx'
  branchKey: 'key_test_oaC7NDTZuZ1khW751jU0Jmjlyqn0q5TH',
  // streamSessionStatusUrl: 'wss://streaming-service.bipper.com/streamSessionStatus',
  // notifyVideoStreamUrl: 'wss://streaming-service.bipper.com/notifyStreamVideo',
  // rtcConfig: {
  //   'iceServers': [{urls: 'stun:3.87.157.5:3478'},
  //     {
  //       urls: 'turn:3.87.157.5:3478',
  //       credential: 'Qwertycoturn!1',
  //       username: 'bsafe'
  //     }]
  // },
  streamSessionStatusUrl: 'wss://streaming-service-test.bipper.com/streamSessionStatus',
  notifyVideoStreamUrl: 'wss://streaming-service-test.diye3m3euq.us-east-1.elasticbeanstalk.com/notifyStreamVideo',
  streamSessionStatusTopicUrl: '/topic/streamStatus',
  serverUrl: 'https://bsafe.bipper.com',
  rtcConfig: {
    'iceServers': [{urls: 'stun:3.87.157.5:3478'},
      {
        urls: 'turn:3.87.157.5:3478',
        credential: 'Qwertycoturn!1',
        username: 'bsafe'
      }]
  },
  firebase: {
    apiKey: 'AIzaSyAO9qb1hQdw58G2lOyDoGMHn8S3_K49GL0',
    authDomain: 'bsafe-d5d38.firebaseapp.com',
    databaseURL: 'https://bsafe-d5d38.firebaseio.com',
    projectId: 'bsafe-d5d38',
    storageBucket: 'bsafe-d5d38.appspot.com',
    messagingSenderId: '555247676435'
  },
  couponsApiKey: 'TZ2xqNj68dC4R6R2'
};
