import { BROWSER_GLOBALS_PROVIDERS } from './browser-globals';
import {
  LazyMapsApiLoaderService, LAZY_MAPS_API_CONFIG, LazyMapsAPILoaderConfigLiteral
} from './services/lazy-maps-api-loader.service';
import { InfoWindowComponent } from './components/info-window/info-window.component';
import { KmlLayerDirective } from './directives/kml-layer.directive';
import { FitBoundsDirective } from './directives/fit-bounds.directive';
import { NgModule } from '@angular/core';
// import { CommonModule } from '@angular/common';
import { MapComponent } from './components/map/map.component';
import { MarkerDirective } from './directives/marker.directive';
import { DataLayerDirective } from './directives/data-layer.directive';
import { MapsAPILoader } from './services/maps-api-loader.service';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { PolylineDirective } from './directives/polyline.directive';
import { PolylinePointDirective } from './directives/polyline-point.directive';

export function coreDirectives() {
  return [
    MapComponent,
    InfoWindowComponent,
    MarkerDirective,
    DataLayerDirective,
    FitBoundsDirective,
    KmlLayerDirective,
    PolylineDirective,
    PolylinePointDirective
  ];
}

@NgModule({
  declarations: [coreDirectives()],
  exports: [coreDirectives()]
})
export class MapModule {
  static forRoot(lazyMapsAPILoaderConfig?: LazyMapsAPILoaderConfigLiteral): ModuleWithProviders {
    return {
      ngModule: MapModule,
      providers: [
        ...BROWSER_GLOBALS_PROVIDERS,
        { provide: MapsAPILoader, useClass: LazyMapsApiLoaderService },
        { provide: LAZY_MAPS_API_CONFIG, useValue: lazyMapsAPILoaderConfig }
      ],
    };
  }
}
