import { apiUrls } from './../../config/index';
import { AuthService } from './auth.service';
import { GenericHttpService } from './generic-http.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  constructor(
    private httpService: GenericHttpService,
    private auth: AuthService
  ) { }

  getClientHistory(page?: number) {
    const url = `${apiUrls.history.base}/${this.auth.userPhone}?showReceived=true&p=${page}`;
    return this.httpService.get(url);
  }

  getAlarmTrace(hash: string) {
    const url = `/m/${hash}`;
    return this.httpService.get(url);
  }

  getUserTraceCoordinates(hash: string) {
    const url = `/m/${hash}/coordinates`;
    return this.httpService.get(url);
  }
}
