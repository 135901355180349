import { storageName } from './../../config/index';
import { NgZone } from '@angular/core';
import { facebookConfig } from '../../config/facebook.config';
import { LocalStorageService } from '@services/local-storage.service';
import * as i0 from "@angular/core";
import * as i1 from "./local-storage.service";
var FacebookService = /** @class */ (function () {
    function FacebookService(zone, localStorageService) {
        this.zone = zone;
        this.localStorageService = localStorageService;
        // this.initFb();
    }
    FacebookService.prototype.login = function () {
        var _this = this;
        return new Promise(function (resolve) {
            _this.initFb().then(function () {
                FB.login(function (res) {
                    if (res.authResponse) {
                        _this.handleUserInfo(res.authResponse);
                        resolve(res.authResponse);
                    }
                    else {
                        FB.getLoginStatus(function (response) {
                            if (response.authResponse) {
                                _this.handleUserInfo(res.authResponse);
                                resolve(response.authResponse);
                            }
                            else {
                                resolve(response);
                            }
                        }, true);
                    }
                }, { auth_type: 'rerequest' });
            });
        });
    };
    FacebookService.prototype.getUserData = function () {
        var _this = this;
        return new Promise(function (resolve) {
            _this.initFb().then(function () {
                FB.api('/me', {
                    fields: ['picture', 'first_name', 'last_name'],
                    access_token: JSON.parse(_this.localStorageService.getItem(storageName.userFBLoginData)).accessToken
                }, function (res) {
                    _this.localStorageService.setObject(storageName.userFBProfileInfo, res);
                    resolve(res);
                });
            });
        });
    };
    FacebookService.prototype.initFb = function () {
        var _this = this;
        var fbConfig = {
            appId: facebookConfig.appId,
            status: false,
            version: facebookConfig.version
        };
        return new Promise(function (resolve) {
            if (!_this.isFbSdkLoaded) {
                _this.loadSdkAsync(function () {
                    _this.isFbSdkLoaded = true;
                    FB.init(fbConfig);
                    resolve();
                });
            }
            else {
                resolve();
            }
        });
    };
    FacebookService.prototype.loadSdkAsync = function (callback) {
        var _this = this;
        window['fbAsyncInit'] = function () { return _this.zone.run(callback); };
        var scriptEl = 'script';
        var id = 'facebook-jssdk';
        var js;
        var fjs = document.getElementsByTagName(scriptEl)[0];
        if (document.getElementById(id)) {
            return;
        }
        js = document.createElement(scriptEl);
        js.id = id;
        js.src = facebookConfig.sdkEnUrl;
        fjs.parentNode.insertBefore(js, fjs);
    };
    FacebookService.prototype.handleUserInfo = function (userFbInfo) {
        this.localStorageService.setObject(storageName.userFBLoginData, userFbInfo);
    };
    FacebookService.ngInjectableDef = i0.defineInjectable({ factory: function FacebookService_Factory() { return new FacebookService(i0.inject(i0.NgZone), i0.inject(i1.LocalStorageService)); }, token: FacebookService, providedIn: "root" });
    return FacebookService;
}());
export { FacebookService };
