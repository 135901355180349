import { storageName } from './../../config/index';
import { Router } from '@angular/router';
import { FacebookLoginModel } from './../models/facebook-login';
import { GenericHttpService } from './generic-http.service';
import { apiUrls } from '../../config';
import { tap } from 'rxjs/operators';
import { CookiesService } from '@services/cookies.service';
import { LocalStorageService } from '@services/local-storage.service';
import * as i0 from "@angular/core";
import * as i1 from "./generic-http.service";
import * as i2 from "./cookies.service";
import * as i3 from "./local-storage.service";
import * as i4 from "@angular/router";
var AuthService = /** @class */ (function () {
    function AuthService(httpService, cookiesService, localStorageService, router) {
        this.httpService = httpService;
        this.cookiesService = cookiesService;
        this.localStorageService = localStorageService;
        this.router = router;
        this.userInfo = JSON.parse(this.localStorageService.getItem(storageName.user));
        this.userPhone = this.userInfo ? this.userInfo.user.fullPhoneNumber : '';
        this.userPhoneObj = {};
    }
    AuthService.prototype.login = function (loginData) {
        return this.httpService.post("" + apiUrls.login, loginData)
            .pipe(tap(this.handleLogin.bind(this)));
    };
    AuthService.prototype.facebookLogin = function (userName, accessToken) {
        var fbLoginModel = {
            userName: userName,
            accessToken: accessToken
        };
        this.userFbLoginData = Object.assign({}, fbLoginModel);
        return this.httpService.post(apiUrls.facebookLogin, fbLoginModel)
            .pipe(tap(this.handleFacebookLogin.bind(this)));
    };
    AuthService.prototype.signUp = function (userData) {
        return this.httpService.post(apiUrls.user.base, userData);
    };
    AuthService.prototype.checkPhoneNumber = function (phoneNumber) {
        var url = apiUrls.user.base + "/" + phoneNumber + apiUrls.user.checkPhoneNumber;
        return this.httpService.get(url);
    };
    AuthService.prototype.sendVerificationCode = function (phoneNumber) {
        var url = apiUrls.user.base + "/" + phoneNumber + apiUrls.user.sendVerificationCode;
        return this.httpService.post(url, {});
    };
    AuthService.prototype.verifyPhoneNumber = function (verifyObj) {
        var url = "" + apiUrls.user.base + apiUrls.user.verifyPhoneNumber;
        return this.httpService.post(url, verifyObj);
    };
    AuthService.prototype.logout = function () {
        this.localStorageService.clear();
        this.cookiesService.deleteCookie('JSESSIONID');
        this.cookiesService.deleteCookie('SESSION');
        this.userPhone = '';
        this.userPhoneObj = {};
        this.router.navigate(['/auth']);
        // Need this to clear auth data
        location.reload();
    };
    AuthService.prototype.handleLogin = function (userInfo) {
        console.log(userInfo);
        this.userInfo = userInfo;
        this.localStorageService.setObject(storageName.user, userInfo);
        // this.cookiesService.setCookie('', response.accessToken);
        // this.changeAuth(true);
    };
    AuthService.prototype.handleFacebookLogin = function (facebookInfo) {
        console.log(facebookInfo);
        this.userInfo = facebookInfo;
        this.localStorageService.setObject(storageName.user, facebookInfo);
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.GenericHttpService), i0.inject(i2.CookiesService), i0.inject(i3.LocalStorageService), i0.inject(i4.Router)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
