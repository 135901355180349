import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'besafe-form-message',
  templateUrl: './form-message.component.html',
  styleUrls: ['./form-message.component.scss']
})
export class FormMessageComponent implements OnInit {
  @Input() show = false;
  @Input() type: string;

  ngOnInit() {
  }

}
