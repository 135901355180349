export const config = {
  accessTokenKey: 'accessToken'
};

export const storageName = {
  appPrefix: 'besafe',
  user: 'user',
  userFBLoginData: 'userFBLoginData',
  userFBProfileInfo: 'userFBProfileInfo'
};

export const positionInterval = {
  base: 60000,
  follow: 15000
};

export const apiUrls = {
  login: '/login',
  facebookLogin: '/login-facebook',
  user: {
    base: '/user',
    baseVersion: '/user/v7',
    checkPhoneNumber: '/checkPhoneNumber',
    verifyPhoneNumber: '/verifyPhoneNumber',
    sendVerificationCode: '/sendVerificationCode'
  },
  checkPhone: '/checkPhoneNumber',
  dictionaries: {
    countries: '/dictionaries/countrycodes',
    countriesAndCodes: '/dictionaries/supported-countries-and-codes'
  },
  guardians: {
    base: '/guardiansv2',
    add: '/add',
    remind: '/remind'
  },
  position: {
    user: '/position/user/',
    guardians: '/guardians'
  },
  follow: {
    base: '/followme',
    start: '/start',
    stop: '/stop'
  },
  watch: {
    base: '/iwatch',
    stop: '/stop'
  },
  history: {
    base: '/client-history'
  },
  push: {
    base: '/push',
    register: '/register',
    unregister: '/unregister'
  }
};
