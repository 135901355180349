import * as i0 from "@angular/core";
var LocationService = /** @class */ (function () {
    function LocationService() {
    }
    LocationService.prototype.getLocation = function () {
        var location = {
            latitude: 0,
            longitude: 0,
            accuracy: 0
        };
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                // location = {...position.coords}
                location.latitude = position.coords.latitude;
                location.longitude = position.coords.longitude;
                location.accuracy = position.coords.accuracy;
            });
        }
        return location;
    };
    LocationService.ngInjectableDef = i0.defineInjectable({ factory: function LocationService_Factory() { return new LocationService(); }, token: LocationService, providedIn: "root" });
    return LocationService;
}());
export { LocationService };
