import { Component, Input, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'besafe-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() color = 'primary';
  @Input() type = 'button';
  @Input() size = 'large';
  @Input() btnDisabled: boolean;
  @Input() noHover: boolean;
  @Input() btnRouterLink: string;
  @Input() btnHref: string;

  @Output() public action = new EventEmitter();

  constructor(
    private router: Router
  ) {}

  clickEvent(): void {
    if (this.btnRouterLink) {
      this.router.navigate([this.btnRouterLink]);
    } else if (this.btnHref) {
      location.href = this.btnHref;
    } else {
      this.action.emit();
    }
  }

}
