import { storageName } from './../../config/index';
import { Injectable, NgZone } from '@angular/core';
import { facebookConfig } from '../../config/facebook.config';
import { LocalStorageService } from '@services/local-storage.service';

declare const FB: any;

@Injectable({
  providedIn: 'root'
})
export class FacebookService {
  isFbSdkLoaded: boolean;

  constructor(
    private zone: NgZone,
    private localStorageService: LocalStorageService
  ) {
    // this.initFb();
  }

  public login(): Promise<any> {
    return new Promise(resolve => {
      this.initFb().then(() => {
        FB.login(res => {
          if (res.authResponse) {
            this.handleUserInfo(res.authResponse);
            resolve(res.authResponse);
          } else {
            FB.getLoginStatus(response => {
              if (response.authResponse) {
                this.handleUserInfo(res.authResponse);
                resolve(response.authResponse);
              } else {
                resolve(response);
              }
            }, true);
          }
        }, { auth_type: 'rerequest' });
      });
    });
  }

  public getUserData(): Promise<any> {
    return new Promise(resolve => {
      this.initFb().then(() => {
        FB.api('/me', {
          fields: ['picture', 'first_name', 'last_name'],
          access_token : JSON.parse(this.localStorageService.getItem(storageName.userFBLoginData)).accessToken
        }, res => {
          this.localStorageService.setObject(storageName.userFBProfileInfo, res);
          resolve(res);
        });
      });
    });
  }


  private initFb(): Promise<any> {
    const fbConfig = {
      appId: facebookConfig.appId,
      status: false,
      version: facebookConfig.version
    };

    return new Promise(resolve => {
      if (!this.isFbSdkLoaded) {
        this.loadSdkAsync(() => {
          this.isFbSdkLoaded = true;
          FB.init(fbConfig);
          resolve();
        });
      } else {
        resolve();
      }
    });
  }

  private loadSdkAsync(callback: () => void): void {
    window['fbAsyncInit'] = () => this.zone.run(callback);
    const scriptEl = 'script';
    const id = 'facebook-jssdk';
    let js;
    const fjs = document.getElementsByTagName(scriptEl)[0];

    if (document.getElementById(id)) {
      return;
    }
    js = document.createElement(scriptEl);
    js.id = id;
    js.src = facebookConfig.sdkEnUrl;
    fjs.parentNode.insertBefore(js, fjs);
  }

  private handleUserInfo(userFbInfo) {
    this.localStorageService.setObject(storageName.userFBLoginData, userFbInfo);
  }
}
