import { Component, OnInit } from '@angular/core';
import { version, buildDate } from '../version';
declare var branch;

@Component({
  selector: 'besafe-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    console.log(`%c This app version is ${version} was build at ${buildDate}.`, 'background: #aa0aad; color: #fff');
  }
}
